// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/footer.tsx");
  import.meta.hot.lastModified = "1715102444056.9734";
}
// REMIX HMR END

export default function Footer() {
  return <div className="fixed z-50 bottom-0 left-0 right-0  text-gray-100 text-center shadow-md py-2 bg-black">
      Plants Team © 2024
    </div>;
}
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;